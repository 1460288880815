.Maincontainer {
  text-align: center;
}

.MapImg {
  width: 45px;
  height: 45px;
}

.MapImgSelected {
  border: 2px solid #800000;
}

.tradeicon {
  height: 40px;
  width: 40px;
}

.msent{
  opacity: 0.4;
  filter: alpha(opacity=40);
  color: #01f000;
}

.traderow{
  max-width: 1050px;
  margin: auto;
}

.tradeText{
  font-size: 0.70em;
}

.tradeCharName{
  inline-size: 100px; 
  overflow: hidden;
  font-size: 0.9em;
}

.mapSelectionText{
  cursor: default;
  user-select: none;
  font-size: 0.9em;
}

.mapSelectionTierText{
  cursor: default;
  user-select: none;
}